@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html {
  font-family: "Manrope", sans-serif;
}

.graphiti {
  font-family: "Londrina Outline", cursive;
}

.swiper {
  width: 100%;
  height: 30vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 80%;
  object-fit: center;
}

.swiper-slide {
  width: 80%;
}

.strokeme {
	-webkit-text-stroke: 2px #F8AF41;
}
